.employeePageContainer {
    margin-block: 1rem;
    width: 95%;
    margin-inline: auto;

    >div {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 2%;
        border-radius: 0.5rem;
        margin-block-end: 1.5rem;
    }
}

.payslipContainer {
    position: relative;
    .payslipHeading {
        display: flex;
        justify-content: space-between;
        padding-block: 2rem;
        padding-inline: 11.5rem;

        h5 {
            margin-block: auto;
        }

        >div {
            >div {
                >div {
                    height: 2.5rem;
                }
            }
        }

    }

    .filterData {
        border: 1px solid yellow;
        position: absolute;
        top: 7%;
        right: 15%;
        background-color: #F5F5F5;
    }

    .payslip {
        border: 1px solid #000000;
        width: 70%;
        margin: auto;
        margin-block-end: 1rem;
        border-radius: 0.5rem;
        padding: 5%;

        .payslipField {
            border: 1px solid #000000;
            border-radius: 0.5rem;

            h4,
            h5 {
                border-top: 1px solid #000000;
                padding: 0.4rem;
            }


            >div:nth-child(1) {
                display: flex;
                justify-content: space-around;

                h2 {
                    margin-block: auto;
                }

                img {
                    width: 8rem;
                    height: 6rem;

                }
            }

            .addressSection {
                border-top: 1px solid #000000;

                h4 {
                    border: unset !important;
                    padding-block: 0px;
                }
            }

            .designationSection {
                border-top: 1px solid #000000;

                h4 {
                    border: unset !important;
                }
            }


            .earningDeductionCOnatiner {
                display: flex;

                >div:nth-child(1) {
                    border-right: 1px solid #000000;
                }

                .earning,
                .deduction {
                    width: 50%;


                    >div {
                        >div:nth-child(1) {
                            border-right: 1px solid #000000;
                        }
                    }
                }
            }
        }
    }


}

.action {
    display: flex;
    justify-content: right;
    margin-block-end: 1rem;

    >div:nth-child(1) {
        margin-inline-end: 0.5rem;
    }
}